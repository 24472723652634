<template>
  <main>
    <div class="container">
      <h1>Добро пожаловать на Витрину вознаграждений <b>Рязанского аккумуляторного завода</b>!</h1>
      <hr>

      <h3>Как это работает?</h3>
      <p>В&nbsp;рамках мотивационной программы для сотрудников на&nbsp;Ваш бонусный счет начисляются баллы, которые можно обменять на&nbsp;электронные подарочные сертификаты.</p>
      <p>Далее Вы&nbsp;попадете в&nbsp;каталог, где сможете выбрать один или несколько подарочных сертификатов известных брендов.</p>
      <p>Выберите сертификат(ы) и&nbsp;введите контактные данные.</p>
      <p>Электронные подарочные сертификаты будут моментально доставлены на&nbsp;указанный email.</p>

      <hr>

      <h3>Возникли вопросы?</h3>
      <p>Свяжитесь с нами в рабочие дни <b>с 08:00 до 20:00 (МСК)</b></p>
      <p>Служба заботы о клиентах: <b><a href="tel:88007009090">8&nbsp;800&nbsp;700 90 90</a></b><br>
      Email: <b><a href="mailto:support@giftery.ru">support@giftery.ru</a></b></p>
      <br>
      <router-link class="button button--large button--rounded" :to="{ name: 'Catalog' }">Выбрать подарок</router-link>
      <br>
      <br>
    </div>
  </main>
</template>

<script>
import 'base/components/Cabinet'

export default {
  name: 'Cabinet',
  mounted: function () {
    this.$store.commit('app/updatePageClass', 'page--cabinet')
  }
}
</script>

<style lang="css" scoped>
  .buttons {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    justify-content: space-between;
    width: 80%;
  }

  .buttons a {
    display: flex;
    text-align: center;
    font-size: 20px;
    padding: 1em;
    width: 32.3%;
    margin-bottom: 15px;
  }

  .buttons a span {
    display: block;
    margin: auto;
    line-height: 1.2;
  }

  @media (max-width: 991px) {
    .buttons {
      flex-wrap: wrap;
      width: auto;
    }

    .buttons a {
      width: 32.3%;
    }
  }

  @media (max-width: 575px) {
    .buttons {
      display: block;
    }

    .buttons a {
      width: auto;
    }
  }

  .text-danger {
    color: #c00;
    font-weight: bold;
  }
</style>
